import {Component, EventEmitter, inject, input, InputSignal, OnDestroy, OnInit, Output} from '@angular/core'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatToolbar, MatToolbarRow} from '@angular/material/toolbar'
import {RouterLink, RouterLinkActive} from '@angular/router'
import {AuthService} from '../../../auth/auth.service'
import {AsyncPipe, NgOptimizedImage} from '@angular/common'
import {BehaviorSubject, combineLatest, map, Observable, Subject, takeUntil} from 'rxjs'
import {DesignService} from '../../../@core/design/design.service'
import {ThemeSwitcherComponent} from '../../../@core/design/theme-switcher/theme-switcher.component'
import {environment} from '../../../../environments/environment'

interface NavButton {
    label: string;
    link: string;
    permission: string | null;
    show$: Observable<boolean>;
}

@Component({
    selector: 'app-navbar',
    standalone: true,
    imports: [
        MatButton,
        MatIcon,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        MatToolbar,
        MatToolbarRow,
        RouterLink,
        RouterLinkActive,
        MatMenuTrigger,
        NgOptimizedImage,
        AsyncPipe,
        ThemeSwitcherComponent
    ],
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnDestroy {
    private authService = inject(AuthService)
    private designService = inject(DesignService)
    useWithAuth: InputSignal<boolean> = input<boolean>(true)
    @Output() onHamburgerClicked = new EventEmitter<void>()

    userName$: Observable<string>
    buttons$: Observable<NavButton[]>
    logoUrl: string = 'https://beattentive.com/wp-content/uploads/2024/03/attentive-logo.png'
    destroy$: Subject<void> = new Subject<void>()

    ngOnInit() {
        this.designService.designSettings$
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                if (data?.logo) {
                    this.logoUrl = data?.logo
                }
            })

        this.initializeUserName()
        this.initializeButtons()
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }

    private initializeUserName() {
        this.userName$ = this.authService.user$.pipe(
            map(user => {
                if (!user) return ''
                return user.first_name && user.last_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.email
            })
        )
    }

    private initializeButtons() {
        const origin = window.location.origin

        const isAttentiveOrOakmoreDomain = origin === 'http://localhost:4200' || origin.includes('attentive') || origin.includes('oakmore')

        this.buttons$ = combineLatest([
            this.authService.isAllowedForAdminAndOakmoreMembers(),
            this.authService.isAllowedOnlyAdmin()
        ]).pipe(
            map(([isAdminOrOakmore, isOnlyAdmin]) => [
                // this.createButton('NACHA Generator', 'nacha-generator', isOnlyAdmin),
                this.createButton('NACHA Generator', 'nacha-generator', !environment.production && isOnlyAdmin),
                this.createButton('Clients', 'clients'),
                this.createButton('Members', 'members'),
                this.createButton('Commission Rates', 'commission-rates', isAdminOrOakmore && isAttentiveOrOakmoreDomain),
                this.createButton('Vendors', 'vendors', isAdminOrOakmore && isAttentiveOrOakmoreDomain),
                this.createButton('Invoices', 'invoices', isOnlyAdmin),
                this.createButton('Brokers', 'brokers', isAdminOrOakmore),
                this.createButton('Agencies', 'agencies', isAdminOrOakmore && isAttentiveOrOakmoreDomain),
                this.createButton('User Manager', 'user-manager', isAdminOrOakmore)
            ])
        )
    }

    private createButton(
        label: string,
        link: string,
        defaultShow = true
    ): NavButton {
        return {
            label,
            link,
            permission: null,
            show$: new BehaviorSubject<boolean>(defaultShow)
        }
    }

    hasPermission(permission: string): Observable<boolean> {
        return combineLatest([
            this.authService.isSuperAdmin(),
            this.authService.hasPermission(permission)
        ]).pipe(
            map(([isSuperAdmin, hasPermission]) => isSuperAdmin || hasPermission)
        )
    }

    toggleMenu() {
        this.onHamburgerClicked.emit()
    }

    logout() {
        this.authService.logout().subscribe()
    }
}
