import {Component} from '@angular/core'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatOption, MatSelect} from '@angular/material/select'
import {DesignService} from '../design.service'

@Component({
    selector: 'app-theme-switcher',
    standalone: true,
    imports: [
        MatFormField,
        MatSelect,
        MatOption,
        MatLabel
    ],
    template: `
        <mat-form-field>
            <mat-label>Theme</mat-label>
            <mat-select [value]="'default'" (selectionChange)="changeTheme($event.value)">
                <mat-option value="default">Default</mat-option>
                <mat-option value="neutral">Neutral</mat-option>
                <mat-option value="azure">Azure</mat-option>
                <mat-option value="red">Red</mat-option>
                <mat-option value="magenta">Magenta</mat-option>
                <mat-option value="orange">Orange</mat-option>
                <mat-option value="spring-green">Spring Green</mat-option>
                <mat-option value="chartreuse">Chartreuse</mat-option>
                <mat-option value="violet">Violet</mat-option>
            </mat-select>
        </mat-form-field>
    `
})
export class ThemeSwitcherComponent {
    constructor(private themeService: DesignService) {
    }

    changeTheme(theme: string) {
        this.themeService.setTheme(theme as any)
    }
}
